import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "mashal_new_client_for_legal" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Catenaccio Sports Agency объявляет о подписании соглашения с ФК «Машал» о предоставлении юридических консультаций в сфере спортивного права.</p>
        </div>
      </div>
    )
  }
}